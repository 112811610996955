<template>
  <v-container>
    <h1>Dashboard</h1>
    <h2>UID:</h2>
    <p>{{ currentUser.uid }}</p>
    <h2>Email:</h2>
    <p>{{ currentUser.email }}</p>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['currentUser', 'userProfile'])
  }
}
</script>

<style>

</style>
